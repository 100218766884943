import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { RegisterRequest } from "@/api/account/request";
import AccountGet from "@/store/account/get";
import AccountRegister from "@/store/account/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import _ from "lodash";
import ShopRoleGet from "@/store/shop-role/get";
import WindowOpen from "@/utils/window-open";
import UlFileRegister from "@/components/UlFileRegister.vue";
import {UlFileRegisiterTabType} from "@/utils/enums";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import AllShopGet from "@/store/account-all-shop/get";
import AcountAgreementAPI from "@/store/account-agreement/get";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDatePicker,
    UIFormRow,
    UlFileRegister,
    quillEditor
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "店舗アカウント設定";
  headingSub = "Login members setting";

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif","image/jpg"];
  tabType = UlFileRegisiterTabType.Photo;

  editorOption = {
    modules: {
      toolbar: [
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"]
      ]
    }
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  isDialogShowing = false;

  inputParams = {
    /** アカウントID（新規:null）*/
    id: null as number | null,
    /** ログインID */
    loginId: "" as string,
    /** パスワード */
    password: "" as null | string,
    /** パスワード確認 */
    passwordConfirm: "" as null | string,
    /** 管理者名 */
    name: "" as string,
    /** メールアドレス */
    email: "" as string,
    /** アカウント有効期限 */
    expireDate: "" as string,
    /** パスワード有効期限 */
    expirationPasswordDate: "" as string,
    /** 状態 0:無効、1:有効 */
    status: 1 as number,
    /** 権限ID */
    roleId: null as null | number,
    /** スタッフページ表示フラグ */
    viewFlg: 1 as number,
    /** 役職 */
    position: "" as string,
    /** キャッチコピー */
    catchcopy: "" as string,
    /** スタッフ説明文 */
    description: "",
    /** スタッフ詳細 */
    detail: "",
    /** 画像編集フラグ */
    imageEditFlg: 0,
    /** 画像パス */
    imagePath: null as string | null,
    /** t_shop_image_histories.id */
    imageId: null,
    /** Twitter連携フラグ */
    twitterFlg: 0 as number,
    /** Twitter */
    twitter: "" as string,
    /** Facebook連携フラグ */
    facebookFlg: 0,
    /** Instagram */
    facebook: null as null | string,
    /** Instagram連携フラグ */
    instagramFlg: 0,
    /** Facebook連携フラグ */
    instagram: null as null | string,
    /** プレビュー */
    isPreview: null as number | null,
    /** Web契約状態 */
    isWebAgreement: false as boolean,
    /** 選択店舗IDリスト */
    shops: []
  } as RegisterRequest;

  /** OEM事業者ID */
  businessId = 0 as number;

  get isEdit() {
    return !!this.id;
  }

  get breadCrumbs() {
    if (this.isEdit) {
      return [
        { text: "設定", disabled: true },
        {
          text: "店舗アカウント設定一覧",
          disabled: false,
          to: { name: "login-members" }
        },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        {
          text: "店舗アカウント設定一覧",
          disabled: false,
          to: { name: "login-members" }
        },
        { text: "新規作成", disabled: true }
      ];
    }
  }

  get shopRoleItems() {
    return ShopRoleGet.getItems;
  }

  get AllShopItems() {
    return AllShopGet.getItems;
  }



  /**
   * mountedライフサイクルフック
   *
   * inputParamsの内容を画面に反映させるために、mountedで実行している
   */
  async mounted() {
    if (this.isEdit) {
      await this.detail();
      this.syncInputParams();
    } else {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      const expireDateString = `${date.getFullYear()}/${date.getMonth() +
        1}/${date.getDate() + 1}`;
      this.inputParams.expireDate = expireDateString;
      this.inputParams.expirationPasswordDate = expireDateString;
      await ShopRoleGet.get({ id: null });
    }
    await AcountAgreementAPI.get();
    if (!AcountAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = AcountAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 3) {
          this.inputParams.isWebAgreement = true;
        }
      });
    }
    await AllShopGet.get();

    //OEM事業者IDを取得
    const storage = BusinessServiceStorage.getLocalStorage();
    this.businessId = storage.id ? storage.id : 0;
    
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await AccountRegister.clearResponse();
    await AccountGet.clearResponse();
    await ShopRoleGet.clearResponse();
    await AllShopGet.clearResponse();
  }

  changeExpireDate(date: string) {
    this.inputParams.expireDate = date;
  }

  changeExpirationPasswordDate(date: string) {
    this.inputParams.expirationPasswordDate = date;
  }

  async goAuthoritySettingOnDialog() {
    await this.$router.replace({
      name: "authority-setting",
      params: { id: AccountRegister.getAccountId.toString() }
    });
  }

  async detail() {
    this.isLoading = true;
    await AccountGet.get({ id: Number(this.id) });
    if (!AccountGet.isSuccess) {
      await Flash.setErrorNow({
        message: AccountGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.syncInputParams();
    this.isLoading = false;
  }

  async webPreview() {
    this.inputParams.isPreview = 2;
    await this.callRegisterApi();
    if (AccountRegister.isSuccess) {
      WindowOpen.webPreview(AccountRegister.getPreviewUrl);
    }
  }
  async register() {
    this.inputParams.isPreview = 0;
    await this.callRegisterApi();
    if (AccountRegister.isSuccess) {
      this.isDialogShowing = true;
    }
  }

  async callRegisterApi() {
    await Flash.clear();
    this.isLoading = true;
    await AccountRegister.register(this.createRegisterRequest());
    if (!AccountRegister.isSuccess) {
      await Flash.setErrorNow({
        message: AccountRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  private syncInputParams() {
    const resultItem = AccountGet.getItems[0];
    this.inputParams.id = resultItem.id;
    this.inputParams.loginId = resultItem.loginId;
    this.inputParams.name = resultItem.name;
    this.inputParams.email = resultItem.email;
    this.inputParams.expireDate = resultItem.expireDate;
    this.inputParams.expirationPasswordDate = resultItem.expirationPasswordDate;
    this.inputParams.status = resultItem.rawStatus;
    this.inputParams.position = resultItem.position;
    this.inputParams.catchcopy = resultItem.catchcopy;
    this.inputParams.description = resultItem.description;
    this.inputParams.detail = resultItem.detail;
    this.inputParams.imagePath = resultItem.imagePath;
    this.inputParams.twitterFlg = resultItem.twitterFlg;
    this.inputParams.twitter = resultItem.twitter;
    this.inputParams.facebookFlg = resultItem.facebookFlg;
    this.inputParams.facebook = resultItem.facebook;
    this.inputParams.instagramFlg = resultItem.instagramFlg;
    this.inputParams.instagram = resultItem.instagram;
    this.initImageUrl = this.inputParams.imagePath;
    this.inputParams.shops = resultItem.shops;
  }

  private createRegisterRequest() {
    const request = _.cloneDeep(this.inputParams);
    if (!this.inputParams.id) {
      delete request.id;
    }
    if (!this.inputParams.password) {
      delete request.password;
    }
    if (!this.inputParams.passwordConfirm) {
      delete request.passwordConfirm;
    }
    if (request.twitterFlg !== 1) {
      delete request.twitter;
    }
    if (request.facebookFlg !== 1) {
      delete request.facebook;
    }
    if (request.instagramFlg !== 1) {
      delete request.instagram;
    }
    return request;
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = data;
    this.inputParams.imageId = id;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = null;
    this.inputParams.imageId = null;
  }

}
