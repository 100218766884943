import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetAccountAgreementResponse } from "@/api/account/response";
import * as AccountShopAPI from "@/api/account";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "account-agreement/get";

/**
 * 【Admin】店舗アカウント関連店舗情報取得API（/get-account-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  GetAccountAgreementResponse: GetAccountAgreementResponse = {} as GetAccountAgreementResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.GetAccountAgreementResponse);
  }

  get getMessage() {
    return this.GetAccountAgreementResponse.message;
  }

  get getagreementsList() {
    if (this.GetAccountAgreementResponse.results) {
      return this.GetAccountAgreementResponse.results || [];
    } else {
      return [];
    }
  }

  // MutationActions
  @MutationAction
  async get() {
    const GetAccountAgreementResponse = await AccountShopAPI.getAccountAgreement();
    return {
      GetAccountAgreementResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      GetAccountAgreementResponse: {} as GetAccountAgreementResponse
    };
  }
}

export default getModule(Get);
