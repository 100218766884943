import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetAccountAllShopResponse } from "@/api/account/response";
import * as AccountShopAPI from "@/api/account";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "account-all-shop/get";

/**
 * 【Admin】店舗アカウント関連店舗情報取得API（/get-account-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  GetAccountAllShopResponse: GetAccountAllShopResponse = {} as GetAccountAllShopResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.GetAccountAllShopResponse);
  }

  get getMessage() {
    return this.GetAccountAllShopResponse.message;
  }

  get getItems() {
    if (this.GetAccountAllShopResponse.results) {
      return this.GetAccountAllShopResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.GetAccountAllShopResponse.results) {
      return this.GetAccountAllShopResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  // MutationActions
  @MutationAction
  async get() {
    const GetAccountAllShopResponse = await AccountShopAPI.getAccountAllShop();
    return {
      GetAccountAllShopResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      GetAccountAllShopResponse: {} as GetAccountAllShopResponse
    };
  }
}

export default getModule(Get);
