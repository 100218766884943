import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { GetResponse, GetResult } from "@/api/account/response";
import { GetRequest } from "@/api/account/request";
import * as AccountAPI from "@/api/account";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "account/get";

/**
 * 店舗アカウントのデコレーター
 */
export class Item {
  constructor(private _item: GetResult) {}
  get id() {
    return this._item.id;
  }
  get loginId() {
    return this._item.loginId;
  }
  get name() {
    return this._item.name;
  }
  get email() {
    return this._item.email;
  }
  get expireDate() {
    return this._item.expireDate.split(" ")[0];
  }
  get expirationPasswordDate() {
    return this._item.expirationPasswordDate.split(" ")[0];
  }
  get status() {
    return this._item.status == 0 ? "無効" : "有効";
  }
  get rawStatus() {
    return this._item.status;
  }
  get viewFlg() {
    return this._item.viewFlg == 0 ? "非公開" : "公開";
  }
  get position() {
    return this._item.position;
  }
  get catchcopy() {
    return this._item.catchcopy;
  }
  get description() {
    return this._item.description;
  }
  get detail() {
    return this._item.detail;
  }
  get imagePath() {
    return this._item.imagePath;
  }
  get twitterFlg() {
    return this._item.twitterFlg;
  }
  get twitter() {
    return this._item.twitter;
  }
  get facebookFlg() {
    return this._item.facebookFlg;
  }
  get facebook() {
    return this._item.facebook;
  }
  get instagramFlg() {
    return this._item.instagramFlg;
  }
  get instagram() {
    return this._item.instagram;
  }
  get shops() {
    return this._item.shops;
  }
}

/**
 * 店舗アカウント設定取得API（/get-account）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  getRequest: GetRequest = {} as GetRequest;
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return [new Item(this.getResponse.results)];
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await AccountAPI.get(getRequest);
    SessionStorage.setObject(MODULE_NAME, getRequest);
    return {
      getRequest,
      getResponse
    };
  }

  @MutationAction
  async restore(initial = {} as GetRequest) {
    const getRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as GetRequest;
    return {
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Search);
