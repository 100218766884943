import service from "@/api/service";
import { GetResponse } from "@/api/shop-role/response";
import { GetRequest } from "@/api/shop-role/request";

/**
 * 権限ロール取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-shop-role", getRequest);
  return response.data as GetResponse;
}
